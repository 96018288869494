"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timezones = void 0;
/* @generated */
// prettier-ignore
exports.timezones = {
    "ad": [
        "Europe/Andorra"
    ],
    "ae": [
        "Asia/Dubai"
    ],
    "af": [
        "Asia/Kabul"
    ],
    "ag": [
        "America/Antigua"
    ],
    "ai": [
        "America/Anguilla"
    ],
    "al": [
        "Europe/Tirane"
    ],
    "am": [
        "Asia/Yerevan"
    ],
    "an": [
        "America/Curacao"
    ],
    "ao": [
        "Africa/Luanda"
    ],
    "aq": [
        "Pacific/Auckland",
        "Antarctica/Casey",
        "Antarctica/Davis",
        "Antarctica/DumontDUrville",
        "Antarctica/Mawson",
        "Antarctica/McMurdo",
        "Antarctica/Palmer",
        "Antarctica/Rothera",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Antarctica/Vostok"
    ],
    "ar": [
        "America/Buenos_Aires",
        "America/Cordoba",
        "America/Catamarca",
        "America/Argentina/La_Rioja",
        "America/Jujuy",
        "America/Argentina/San_Luis",
        "America/Mendoza",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/Tucuman",
        "America/Argentina/San_Juan",
        "America/Argentina/Ushuaia"
    ],
    "as": [
        "Pacific/Pago_Pago"
    ],
    "at": [
        "Europe/Vienna"
    ],
    "au": [
        "Australia/Adelaide",
        "Australia/Broken_Hill",
        "Australia/Brisbane",
        "Australia/Darwin",
        "Australia/Eucla",
        "Australia/Hobart",
        "Australia/Currie",
        "Australia/Lindeman",
        "Australia/Lord_Howe",
        "Australia/Melbourne",
        "Antarctica/Macquarie",
        "Australia/Perth",
        "Australia/Sydney"
    ],
    "aw": [
        "America/Aruba"
    ],
    "az": [
        "Asia/Baku"
    ],
    "ba": [
        "Europe/Sarajevo"
    ],
    "bb": [
        "America/Barbados"
    ],
    "bd": [
        "Asia/Dhaka"
    ],
    "be": [
        "Europe/Brussels"
    ],
    "bf": [
        "Africa/Ouagadougou"
    ],
    "bg": [
        "Europe/Sofia"
    ],
    "bh": [
        "Asia/Bahrain"
    ],
    "bi": [
        "Africa/Bujumbura"
    ],
    "bj": [
        "Africa/Porto-Novo"
    ],
    "bm": [
        "Atlantic/Bermuda"
    ],
    "bn": [
        "Asia/Brunei"
    ],
    "bo": [
        "America/La_Paz"
    ],
    "bq": [
        "America/Kralendijk"
    ],
    "br": [
        "America/Araguaina",
        "America/Belem",
        "America/Boa_Vista",
        "America/Cuiaba",
        "America/Campo_Grande",
        "America/Eirunepe",
        "America/Noronha",
        "America/Fortaleza",
        "America/Manaus",
        "America/Maceio",
        "America/Porto_Velho",
        "America/Rio_Branco",
        "America/Recife",
        "America/Sao_Paulo",
        "America/Bahia",
        "America/Santarem"
    ],
    "bs": [
        "America/Nassau"
    ],
    "bt": [
        "Asia/Thimphu"
    ],
    "bw": [
        "Africa/Gaborone"
    ],
    "by": [
        "Europe/Minsk"
    ],
    "bz": [
        "America/Belize"
    ],
    "ca": [
        "America/Creston",
        "America/Edmonton",
        "America/Rainy_River",
        "America/Fort_Nelson",
        "America/Glace_Bay",
        "America/Goose_Bay",
        "America/Halifax",
        "America/Iqaluit",
        "America/Moncton",
        "America/Montreal",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Resolute",
        "America/Regina",
        "America/St_Johns",
        "America/Thunder_Bay",
        "America/Toronto",
        "America/Vancouver",
        "America/Winnipeg",
        "America/Blanc-Sablon",
        "America/Cambridge_Bay",
        "America/Dawson",
        "America/Dawson_Creek",
        "America/Rankin_Inlet",
        "America/Inuvik",
        "America/Whitehorse",
        "America/Swift_Current",
        "America/Yellowknife",
        "America/Coral_Harbour"
    ],
    "cc": [
        "Indian/Cocos"
    ],
    "cd": [
        "Africa/Lubumbashi",
        "Africa/Kinshasa"
    ],
    "cf": [
        "Africa/Bangui"
    ],
    "cg": [
        "Africa/Brazzaville"
    ],
    "ch": [
        "Europe/Zurich"
    ],
    "ci": [
        "Africa/Abidjan"
    ],
    "ck": [
        "Pacific/Rarotonga"
    ],
    "cl": [
        "Pacific/Easter",
        "America/Punta_Arenas",
        "America/Santiago"
    ],
    "cm": [
        "Africa/Douala"
    ],
    "cn": [
        "Asia/Shanghai",
        "Asia/Shanghai",
        "Asia/Urumqi",
        "Asia/Shanghai",
        "Asia/Urumqi"
    ],
    "co": [
        "America/Bogota"
    ],
    "cr": [
        "America/Costa_Rica"
    ],
    "cs": [
        "CST6CDT"
    ],
    "cu": [
        "America/Havana"
    ],
    "cv": [
        "Atlantic/Cape_Verde"
    ],
    "cx": [
        "Indian/Christmas"
    ],
    "cy": [
        "Asia/Famagusta",
        "Asia/Nicosia"
    ],
    "cz": [
        "Europe/Prague"
    ],
    "de": [
        "Europe/Berlin",
        "Europe/Busingen"
    ],
    "dj": [
        "Africa/Djibouti"
    ],
    "dk": [
        "Europe/Copenhagen"
    ],
    "dm": [
        "America/Dominica"
    ],
    "do": [
        "America/Santo_Domingo"
    ],
    "dz": [
        "Africa/Algiers"
    ],
    "ec": [
        "Pacific/Galapagos",
        "America/Guayaquil"
    ],
    "ee": [
        "Europe/Tallinn"
    ],
    "eg": [
        "Africa/Cairo"
    ],
    "eh": [
        "Africa/El_Aaiun"
    ],
    "er": [
        "Africa/Asmera"
    ],
    "es": [
        "Africa/Ceuta",
        "Atlantic/Canary",
        "Europe/Madrid",
        "EST5EDT"
    ],
    "et": [
        "Africa/Addis_Ababa"
    ],
    "fi": [
        "Europe/Helsinki",
        "Europe/Mariehamn"
    ],
    "fj": [
        "Pacific/Fiji"
    ],
    "fk": [
        "Atlantic/Stanley"
    ],
    "fm": [
        "Pacific/Kosrae",
        "Pacific/Ponape",
        "Pacific/Truk"
    ],
    "fo": [
        "Atlantic/Faeroe"
    ],
    "fr": [
        "Europe/Paris"
    ],
    "ga": [
        "Africa/Libreville",
        "Asia/Gaza",
        "Asia/Gaza"
    ],
    "gb": [
        "Europe/London"
    ],
    "gd": [
        "America/Grenada"
    ],
    "ge": [
        "Asia/Tbilisi"
    ],
    "gf": [
        "America/Cayenne"
    ],
    "gg": [
        "Europe/Guernsey"
    ],
    "gh": [
        "Africa/Accra"
    ],
    "gi": [
        "Europe/Gibraltar"
    ],
    "gl": [
        "America/Danmarkshavn",
        "America/Godthab",
        "America/Scoresbysund",
        "America/Thule"
    ],
    "gm": [
        "Africa/Banjul",
        "Etc/GMT"
    ],
    "gn": [
        "Africa/Conakry"
    ],
    "gp": [
        "America/Guadeloupe",
        "America/Marigot",
        "America/St_Barthelemy"
    ],
    "gq": [
        "Africa/Malabo"
    ],
    "gr": [
        "Europe/Athens"
    ],
    "gs": [
        "Atlantic/South_Georgia"
    ],
    "gt": [
        "America/Guatemala"
    ],
    "gu": [
        "Pacific/Guam"
    ],
    "gw": [
        "Africa/Bissau"
    ],
    "gy": [
        "America/Guyana"
    ],
    "he": [
        "Asia/Hebron"
    ],
    "hk": [
        "Asia/Hong_Kong"
    ],
    "hn": [
        "America/Tegucigalpa"
    ],
    "hr": [
        "Europe/Zagreb"
    ],
    "ht": [
        "America/Port-au-Prince"
    ],
    "hu": [
        "Europe/Budapest"
    ],
    "id": [
        "Asia/Jayapura",
        "Asia/Jakarta",
        "Asia/Makassar",
        "Asia/Pontianak"
    ],
    "ie": [
        "Europe/Dublin"
    ],
    "im": [
        "Europe/Isle_of_Man"
    ],
    "in": [
        "Asia/Calcutta"
    ],
    "io": [
        "Indian/Chagos"
    ],
    "iq": [
        "Asia/Baghdad"
    ],
    "ir": [
        "Asia/Tehran"
    ],
    "is": [
        "Atlantic/Reykjavik"
    ],
    "it": [
        "Europe/Rome"
    ],
    "je": [
        "Asia/Jerusalem",
        "Europe/Jersey"
    ],
    "jm": [
        "America/Jamaica"
    ],
    "jo": [
        "Asia/Amman"
    ],
    "jp": [
        "Asia/Tokyo"
    ],
    "ke": [
        "Africa/Nairobi"
    ],
    "kg": [
        "Asia/Bishkek"
    ],
    "kh": [
        "Asia/Phnom_Penh"
    ],
    "ki": [
        "Pacific/Kiritimati",
        "Pacific/Enderbury",
        "Pacific/Tarawa"
    ],
    "km": [
        "Indian/Comoro"
    ],
    "kn": [
        "America/St_Kitts"
    ],
    "kp": [
        "Asia/Pyongyang"
    ],
    "kr": [
        "Asia/Seoul"
    ],
    "kw": [
        "Asia/Kuwait"
    ],
    "ky": [
        "America/Cayman"
    ],
    "kz": [
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Almaty",
        "Asia/Atyrau",
        "Asia/Qostanay",
        "Asia/Qyzylorda",
        "Asia/Oral"
    ],
    "la": [
        "Asia/Vientiane"
    ],
    "lb": [
        "Asia/Beirut"
    ],
    "lc": [
        "America/St_Lucia"
    ],
    "li": [
        "Europe/Vaduz"
    ],
    "lk": [
        "Asia/Colombo"
    ],
    "lr": [
        "Africa/Monrovia"
    ],
    "ls": [
        "Africa/Maseru"
    ],
    "lt": [
        "Europe/Vilnius"
    ],
    "lu": [
        "Europe/Luxembourg"
    ],
    "lv": [
        "Europe/Riga"
    ],
    "ly": [
        "Africa/Tripoli"
    ],
    "ma": [
        "Africa/Casablanca"
    ],
    "mc": [
        "Europe/Monaco"
    ],
    "md": [
        "Europe/Chisinau"
    ],
    "me": [
        "Europe/Podgorica"
    ],
    "mg": [
        "Indian/Antananarivo"
    ],
    "mh": [
        "Pacific/Kwajalein",
        "Pacific/Majuro"
    ],
    "mk": [
        "Europe/Skopje"
    ],
    "ml": [
        "Africa/Bamako"
    ],
    "mm": [
        "Asia/Rangoon"
    ],
    "mn": [
        "Asia/Choibalsan",
        "Asia/Hovd",
        "Asia/Ulaanbaatar"
    ],
    "mo": [
        "Asia/Macau"
    ],
    "mp": [
        "Pacific/Saipan"
    ],
    "mq": [
        "America/Martinique"
    ],
    "mr": [
        "Africa/Nouakchott"
    ],
    "ms": [
        "America/Montserrat",
        "MST7MDT"
    ],
    "mt": [
        "Europe/Malta"
    ],
    "mu": [
        "Indian/Mauritius"
    ],
    "mv": [
        "Indian/Maldives"
    ],
    "mw": [
        "Africa/Blantyre"
    ],
    "mx": [
        "America/Chihuahua",
        "America/Cancun",
        "America/Hermosillo",
        "America/Matamoros",
        "America/Mexico_City",
        "America/Merida",
        "America/Monterrey",
        "America/Mazatlan",
        "America/Ojinaga",
        "America/Bahia_Banderas",
        "America/Santa_Isabel",
        "America/Tijuana"
    ],
    "my": [
        "Asia/Kuching",
        "Asia/Kuala_Lumpur"
    ],
    "mz": [
        "Africa/Maputo"
    ],
    "na": [
        "Africa/Windhoek"
    ],
    "nc": [
        "Pacific/Noumea"
    ],
    "ne": [
        "Africa/Niamey"
    ],
    "nf": [
        "Pacific/Norfolk"
    ],
    "ng": [
        "Africa/Lagos"
    ],
    "ni": [
        "America/Managua"
    ],
    "nl": [
        "Europe/Amsterdam"
    ],
    "no": [
        "Europe/Oslo"
    ],
    "np": [
        "Asia/Katmandu"
    ],
    "nr": [
        "Pacific/Nauru"
    ],
    "nu": [
        "Pacific/Niue"
    ],
    "nz": [
        "Pacific/Auckland",
        "Pacific/Chatham"
    ],
    "om": [
        "Asia/Muscat"
    ],
    "pa": [
        "America/Panama"
    ],
    "pe": [
        "America/Lima"
    ],
    "pf": [
        "Pacific/Gambier",
        "Pacific/Marquesas",
        "Pacific/Tahiti"
    ],
    "pg": [
        "Pacific/Port_Moresby",
        "Pacific/Bougainville"
    ],
    "ph": [
        "Asia/Manila"
    ],
    "pk": [
        "Asia/Karachi"
    ],
    "pl": [
        "Europe/Warsaw"
    ],
    "pm": [
        "America/Miquelon"
    ],
    "pn": [
        "Pacific/Pitcairn"
    ],
    "pr": [
        "America/Puerto_Rico"
    ],
    "ps": [
        "PST8PDT"
    ],
    "pt": [
        "Atlantic/Madeira",
        "Europe/Lisbon",
        "Atlantic/Azores"
    ],
    "pw": [
        "Pacific/Palau"
    ],
    "py": [
        "America/Asuncion"
    ],
    "qa": [
        "Asia/Qatar"
    ],
    "re": [
        "Indian/Reunion"
    ],
    "ro": [
        "Europe/Bucharest"
    ],
    "rs": [
        "Europe/Belgrade"
    ],
    "ru": [
        "Europe/Astrakhan",
        "Asia/Barnaul",
        "Asia/Chita",
        "Asia/Anadyr",
        "Asia/Magadan",
        "Asia/Irkutsk",
        "Europe/Kaliningrad",
        "Asia/Khandyga",
        "Asia/Krasnoyarsk",
        "Europe/Samara",
        "Europe/Kirov",
        "Europe/Moscow",
        "Asia/Novokuznetsk",
        "Asia/Omsk",
        "Asia/Novosibirsk",
        "Asia/Kamchatka",
        "Europe/Saratov",
        "Asia/Srednekolymsk",
        "Asia/Tomsk",
        "Europe/Ulyanovsk",
        "Asia/Ust-Nera",
        "Asia/Sakhalin",
        "Europe/Volgograd",
        "Asia/Vladivostok",
        "Asia/Yekaterinburg",
        "Asia/Yakutsk"
    ],
    "rw": [
        "Africa/Kigali"
    ],
    "sa": [
        "Asia/Riyadh"
    ],
    "sb": [
        "Pacific/Guadalcanal"
    ],
    "sc": [
        "Indian/Mahe"
    ],
    "sd": [
        "Africa/Khartoum"
    ],
    "se": [
        "Europe/Stockholm"
    ],
    "sg": [
        "Asia/Singapore"
    ],
    "sh": [
        "Atlantic/St_Helena"
    ],
    "si": [
        "Europe/Ljubljana"
    ],
    "sj": [
        "Arctic/Longyearbyen"
    ],
    "sk": [
        "Europe/Bratislava"
    ],
    "sl": [
        "Africa/Freetown"
    ],
    "sm": [
        "Europe/San_Marino"
    ],
    "sn": [
        "Africa/Dakar"
    ],
    "so": [
        "Africa/Mogadishu"
    ],
    "sr": [
        "America/Paramaribo"
    ],
    "ss": [
        "Africa/Juba"
    ],
    "st": [
        "Africa/Sao_Tome"
    ],
    "sv": [
        "America/El_Salvador"
    ],
    "sx": [
        "America/Lower_Princes"
    ],
    "sy": [
        "Asia/Damascus"
    ],
    "sz": [
        "Africa/Mbabane"
    ],
    "tc": [
        "America/Grand_Turk"
    ],
    "td": [
        "Africa/Ndjamena"
    ],
    "tf": [
        "Indian/Kerguelen"
    ],
    "tg": [
        "Africa/Lome"
    ],
    "th": [
        "Asia/Bangkok"
    ],
    "tj": [
        "Asia/Dushanbe"
    ],
    "tk": [
        "Pacific/Fakaofo"
    ],
    "tl": [
        "Asia/Dili"
    ],
    "tm": [
        "Asia/Ashgabat"
    ],
    "tn": [
        "Africa/Tunis"
    ],
    "to": [
        "Pacific/Tongatapu"
    ],
    "tr": [
        "Europe/Istanbul"
    ],
    "tt": [
        "America/Port_of_Spain"
    ],
    "tv": [
        "Pacific/Funafuti"
    ],
    "tw": [
        "Asia/Taipei"
    ],
    "tz": [
        "Africa/Dar_es_Salaam"
    ],
    "ua": [
        "Europe/Kiev",
        "Europe/Zaporozhye",
        "Europe/Simferopol",
        "Europe/Uzhgorod"
    ],
    "ug": [
        "Africa/Kampala"
    ],
    "um": [
        "Pacific/Wake",
        "Pacific/Johnston",
        "Pacific/Midway"
    ],
    "un": [
        "Etc/Unknown"
    ],
    "us": [
        "America/Adak",
        "America/Indiana/Marengo",
        "America/Anchorage",
        "America/Boise",
        "America/Chicago",
        "America/Denver",
        "America/Detroit",
        "Pacific/Honolulu",
        "America/Indianapolis",
        "America/Indiana/Vevay",
        "America/Juneau",
        "America/Indiana/Knox",
        "America/Los_Angeles",
        "America/Louisville",
        "America/Menominee",
        "America/Kentucky/Monticello",
        "America/Metlakatla",
        "America/Denver",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/New_York",
        "America/Indiana/Vincennes",
        "America/Nome",
        "America/Phoenix",
        "America/Sitka",
        "America/Indiana/Tell_City",
        "America/Indiana/Winamac",
        "America/Indiana/Petersburg",
        "America/North_Dakota/Beulah",
        "America/Yakutat"
    ],
    "ut": [
        "Etc/UTC",
        "Etc/GMT-1",
        "Etc/GMT-2",
        "Etc/GMT-3",
        "Etc/GMT-4",
        "Etc/GMT-5",
        "Etc/GMT-6",
        "Etc/GMT-7",
        "Etc/GMT-8",
        "Etc/GMT-9",
        "Etc/GMT-10",
        "Etc/GMT-11",
        "Etc/GMT-12",
        "Etc/GMT-13",
        "Etc/GMT-14",
        "Etc/GMT+1",
        "Etc/GMT+2",
        "Etc/GMT+3",
        "Etc/GMT+4",
        "Etc/GMT+5",
        "Etc/GMT+6",
        "Etc/GMT+7",
        "Etc/GMT+8",
        "Etc/GMT+9",
        "Etc/GMT+10",
        "Etc/GMT+11",
        "Etc/GMT+12"
    ],
    "uy": [
        "America/Montevideo"
    ],
    "uz": [
        "Asia/Samarkand",
        "Asia/Tashkent"
    ],
    "va": [
        "Europe/Vatican"
    ],
    "vc": [
        "America/St_Vincent"
    ],
    "ve": [
        "America/Caracas"
    ],
    "vg": [
        "America/Tortola"
    ],
    "vi": [
        "America/St_Thomas"
    ],
    "vn": [
        "Asia/Saigon"
    ],
    "vu": [
        "Pacific/Efate"
    ],
    "wf": [
        "Pacific/Wallis"
    ],
    "ws": [
        "Pacific/Apia"
    ],
    "ye": [
        "Asia/Aden"
    ],
    "yt": [
        "Indian/Mayotte"
    ],
    "za": [
        "Africa/Johannesburg"
    ],
    "zm": [
        "Africa/Lusaka"
    ],
    "zw": [
        "Africa/Harare"
    ]
};
