/* @generated */	
// prettier-ignore
if (Intl.DateTimeFormat && typeof Intl.DateTimeFormat.__addLocaleData === 'function') {
  Intl.DateTimeFormat.__addLocaleData({
  "data": {
    "am": "am",
    "pm": "pm",
    "weekday": {
      "narrow": [
        "S",
        "M",
        "T",
        "W",
        "T",
        "F",
        "S"
      ],
      "short": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "long": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ]
    },
    "era": {
      "narrow": {
        "BC": "B",
        "AD": "A"
      },
      "short": {
        "BC": "BC",
        "AD": "AD"
      },
      "long": {
        "BC": "Before Christ",
        "AD": "Anno Domini"
      }
    },
    "month": {
      "narrow": [
        "J",
        "F",
        "M",
        "A",
        "M",
        "J",
        "J",
        "A",
        "S",
        "O",
        "N",
        "D"
      ],
      "short": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "long": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    },
    "timeZoneName": {
      "America/Rio_Branco": {
        "long": [
          "Acre Standard Time",
          "Acre Summer Time"
        ]
      },
      "Asia/Kabul": {
        "long": [
          "Afghanistan Time",
          "Afghanistan Time"
        ]
      },
      "Africa/Maputo": {
        "long": [
          "Central Africa Time",
          "Central Africa Time"
        ]
      },
      "Africa/Bujumbura": {
        "long": [
          "Central Africa Time",
          "Central Africa Time"
        ]
      },
      "Africa/Gaborone": {
        "long": [
          "Central Africa Time",
          "Central Africa Time"
        ]
      },
      "Africa/Lubumbashi": {
        "long": [
          "Central Africa Time",
          "Central Africa Time"
        ]
      },
      "Africa/Blantyre": {
        "long": [
          "Central Africa Time",
          "Central Africa Time"
        ]
      },
      "Africa/Kigali": {
        "long": [
          "Central Africa Time",
          "Central Africa Time"
        ]
      },
      "Africa/Lusaka": {
        "long": [
          "Central Africa Time",
          "Central Africa Time"
        ]
      },
      "Africa/Harare": {
        "long": [
          "Central Africa Time",
          "Central Africa Time"
        ]
      },
      "Africa/Nairobi": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Africa/Djibouti": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Africa/Asmera": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Africa/Addis_Ababa": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Indian/Comoro": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Indian/Antananarivo": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Africa/Mogadishu": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Africa/Dar_es_Salaam": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Africa/Kampala": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Indian/Mayotte": {
        "long": [
          "East Africa Time",
          "East Africa Time"
        ]
      },
      "Africa/Johannesburg": {
        "long": [
          "South Africa Standard Time",
          "South Africa Standard Time"
        ]
      },
      "Africa/Maseru": {
        "long": [
          "South Africa Standard Time",
          "South Africa Standard Time"
        ]
      },
      "Africa/Mbabane": {
        "long": [
          "South Africa Standard Time",
          "South Africa Standard Time"
        ]
      },
      "Africa/Lagos": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Luanda": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Porto-Novo": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Kinshasa": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Bangui": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Brazzaville": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Douala": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Libreville": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Malabo": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Niamey": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Africa/Ndjamena": {
        "long": [
          "West Africa Standard Time",
          "West Africa Summer Time"
        ]
      },
      "Asia/Aqtobe": {
        "long": [
          "West Kazakhstan Time",
          "West Kazakhstan Time"
        ]
      },
      "America/Juneau": {
        "long": [
          "Alaska Standard Time",
          "Alaska Daylight Time"
        ]
      },
      "Asia/Almaty": {
        "long": [
          "East Kazakhstan Time",
          "East Kazakhstan Time"
        ]
      },
      "America/Manaus": {
        "long": [
          "Amazon Standard Time",
          "Amazon Summer Time"
        ]
      },
      "America/Chicago": {
        "long": [
          "Central Standard Time",
          "Central Daylight Time"
        ]
      },
      "America/Belize": {
        "long": [
          "Central Standard Time",
          "Central Daylight Time"
        ]
      },
      "America/Winnipeg": {
        "long": [
          "Central Standard Time",
          "Central Daylight Time"
        ]
      },
      "America/Costa_Rica": {
        "long": [
          "Central Standard Time",
          "Central Daylight Time"
        ]
      },
      "America/Guatemala": {
        "long": [
          "Central Standard Time",
          "Central Daylight Time"
        ]
      },
      "America/Tegucigalpa": {
        "long": [
          "Central Standard Time",
          "Central Daylight Time"
        ]
      },
      "America/Mexico_City": {
        "long": [
          "Central Standard Time",
          "Central Daylight Time"
        ]
      },
      "America/El_Salvador": {
        "long": [
          "Central Standard Time",
          "Central Daylight Time"
        ]
      },
      "America/New_York": {
        "long": [
          "Eastern Standard Time",
          "Eastern Daylight Time"
        ]
      },
      "America/Nassau": {
        "long": [
          "Eastern Standard Time",
          "Eastern Daylight Time"
        ]
      },
      "America/Toronto": {
        "long": [
          "Eastern Standard Time",
          "Eastern Daylight Time"
        ]
      },
      "America/Port-au-Prince": {
        "long": [
          "Eastern Standard Time",
          "Eastern Daylight Time"
        ]
      },
      "America/Jamaica": {
        "long": [
          "Eastern Standard Time",
          "Eastern Daylight Time"
        ]
      },
      "America/Cayman": {
        "long": [
          "Eastern Standard Time",
          "Eastern Daylight Time"
        ]
      },
      "America/Panama": {
        "long": [
          "Eastern Standard Time",
          "Eastern Daylight Time"
        ]
      },
      "America/Denver": {
        "long": [
          "Mountain Standard Time",
          "Mountain Daylight Time"
        ]
      },
      "America/Edmonton": {
        "long": [
          "Mountain Standard Time",
          "Mountain Daylight Time"
        ]
      },
      "America/Hermosillo": {
        "long": [
          "Mountain Standard Time",
          "Mountain Daylight Time"
        ]
      },
      "America/Los_Angeles": {
        "long": [
          "Pacific Standard Time",
          "Pacific Daylight Time"
        ]
      },
      "America/Vancouver": {
        "long": [
          "Pacific Standard Time",
          "Pacific Daylight Time"
        ]
      },
      "America/Tijuana": {
        "long": [
          "Pacific Standard Time",
          "Pacific Daylight Time"
        ]
      },
      "Asia/Anadyr": {
        "long": [
          "Anadyr Standard Time",
          "Anadyr Summer Time"
        ]
      },
      "Pacific/Apia": {
        "long": [
          "Apia Standard Time",
          "Apia Daylight Time"
        ]
      },
      "Asia/Riyadh": {
        "long": [
          "Arabian Standard Time",
          "Arabian Daylight Time"
        ]
      },
      "Asia/Bahrain": {
        "long": [
          "Arabian Standard Time",
          "Arabian Daylight Time"
        ]
      },
      "Asia/Baghdad": {
        "long": [
          "Arabian Standard Time",
          "Arabian Daylight Time"
        ]
      },
      "Asia/Kuwait": {
        "long": [
          "Arabian Standard Time",
          "Arabian Daylight Time"
        ]
      },
      "Asia/Qatar": {
        "long": [
          "Arabian Standard Time",
          "Arabian Daylight Time"
        ]
      },
      "Asia/Aden": {
        "long": [
          "Arabian Standard Time",
          "Arabian Daylight Time"
        ]
      },
      "America/Buenos_Aires": {
        "long": [
          "Argentina Standard Time",
          "Argentina Summer Time"
        ]
      },
      "America/Argentina/San_Luis": {
        "long": [
          "Western Argentina Standard Time",
          "Western Argentina Summer Time"
        ]
      },
      "Asia/Ashgabat": {
        "long": [
          "Turkmenistan Standard Time",
          "Turkmenistan Summer Time"
        ]
      },
      "America/Halifax": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Antigua": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Anguilla": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Aruba": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Barbados": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "Atlantic/Bermuda": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Kralendijk": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Curacao": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Dominica": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Grenada": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Thule": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Guadeloupe": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/St_Kitts": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/St_Lucia": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Marigot": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Martinique": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Montserrat": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Puerto_Rico": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Lower_Princes": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Port_of_Spain": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/St_Vincent": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/Tortola": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "America/St_Thomas": {
        "long": [
          "Atlantic Standard Time",
          "Atlantic Daylight Time"
        ]
      },
      "Australia/Adelaide": {
        "long": [
          "Australian Central Standard Time",
          "Australian Central Daylight Time"
        ]
      },
      "Australia/Eucla": {
        "long": [
          "Australian Central Western Standard Time",
          "Australian Central Western Daylight Time"
        ]
      },
      "Australia/Sydney": {
        "long": [
          "Australian Eastern Standard Time",
          "Australian Eastern Daylight Time"
        ]
      },
      "Australia/Perth": {
        "long": [
          "Australian Western Standard Time",
          "Australian Western Daylight Time"
        ]
      },
      "Atlantic/Azores": {
        "long": [
          "Azores Standard Time",
          "Azores Summer Time"
        ]
      },
      "Asia/Thimphu": {
        "long": [
          "Bhutan Time",
          "Bhutan Time"
        ]
      },
      "America/La_Paz": {
        "long": [
          "Bolivia Time",
          "Bolivia Time"
        ]
      },
      "Asia/Kuching": {
        "long": [
          "Malaysia Time",
          "Malaysia Time"
        ]
      },
      "America/Sao_Paulo": {
        "long": [
          "Brasilia Standard Time",
          "Brasilia Summer Time"
        ]
      },
      "Europe/London": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Asia/Brunei": {
        "long": [
          "Brunei Darussalam Time",
          "Brunei Darussalam Time"
        ]
      },
      "Atlantic/Cape_Verde": {
        "long": [
          "Cape Verde Standard Time",
          "Cape Verde Summer Time"
        ]
      },
      "Antarctica/Casey": {
        "long": [
          "Casey Time",
          "Casey Time"
        ]
      },
      "Pacific/Saipan": {
        "long": [
          "North Mariana Islands Time",
          "North Mariana Islands Time"
        ]
      },
      "Pacific/Guam": {
        "long": [
          "Guam Standard Time",
          "Guam Standard Time"
        ]
      },
      "Pacific/Chatham": {
        "long": [
          "Chatham Standard Time",
          "Chatham Daylight Time"
        ]
      },
      "America/Santiago": {
        "long": [
          "Chile Standard Time",
          "Chile Summer Time"
        ]
      },
      "Asia/Shanghai": {
        "long": [
          "China Standard Time",
          "China Daylight Time"
        ]
      },
      "Asia/Choibalsan": {
        "long": [
          "Choibalsan Standard Time",
          "Choibalsan Summer Time"
        ]
      },
      "Indian/Christmas": {
        "long": [
          "Christmas Island Time",
          "Christmas Island Time"
        ]
      },
      "Indian/Cocos": {
        "long": [
          "Cocos Islands Time",
          "Cocos Islands Time"
        ]
      },
      "America/Bogota": {
        "long": [
          "Colombia Standard Time",
          "Colombia Summer Time"
        ]
      },
      "Pacific/Rarotonga": {
        "long": [
          "Cook Islands Standard Time",
          "Cook Islands Half Summer Time"
        ]
      },
      "America/Havana": {
        "long": [
          "Cuba Standard Time",
          "Cuba Daylight Time"
        ]
      },
      "Antarctica/Davis": {
        "long": [
          "Davis Time",
          "Davis Time"
        ]
      },
      "Antarctica/DumontDUrville": {
        "long": [
          "Dumont-d’Urville Time",
          "Dumont-d’Urville Time"
        ]
      },
      "Asia/Dushanbe": {
        "long": [
          "Tajikistan Time",
          "Tajikistan Time"
        ]
      },
      "America/Paramaribo": {
        "long": [
          "Suriname Time",
          "Suriname Time"
        ]
      },
      "Asia/Dili": {
        "long": [
          "East Timor Time",
          "East Timor Time"
        ]
      },
      "Pacific/Easter": {
        "long": [
          "Easter Island Standard Time",
          "Easter Island Summer Time"
        ]
      },
      "America/Guayaquil": {
        "long": [
          "Ecuador Time",
          "Ecuador Time"
        ]
      },
      "Europe/Paris": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Andorra": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Tirane": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Vienna": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Sarajevo": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Brussels": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Zurich": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Prague": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Berlin": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Copenhagen": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Madrid": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Gibraltar": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Zagreb": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Budapest": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Rome": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Vaduz": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Luxembourg": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Monaco": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Podgorica": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Skopje": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Malta": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Amsterdam": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Oslo": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Warsaw": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Belgrade": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Stockholm": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Ljubljana": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Arctic/Longyearbyen": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Bratislava": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/San_Marino": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Africa/Tunis": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Vatican": {
        "long": [
          "Central European Standard Time",
          "Central European Summer Time"
        ],
        "short": [
          "CET",
          "CEST"
        ]
      },
      "Europe/Bucharest": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Europe/Mariehamn": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Europe/Sofia": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Asia/Nicosia": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Africa/Cairo": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Europe/Helsinki": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Europe/Athens": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Asia/Amman": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Asia/Beirut": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Asia/Damascus": {
        "long": [
          "Eastern European Standard Time",
          "Eastern European Summer Time"
        ],
        "short": [
          "EET",
          "EEST"
        ]
      },
      "Europe/Minsk": {
        "long": [
          "Further-eastern European Time",
          "Further-eastern European Time"
        ]
      },
      "Europe/Kaliningrad": {
        "long": [
          "Further-eastern European Time",
          "Further-eastern European Time"
        ]
      },
      "Atlantic/Canary": {
        "long": [
          "Western European Standard Time",
          "Western European Summer Time"
        ],
        "short": [
          "WET",
          "WEST"
        ]
      },
      "Atlantic/Faeroe": {
        "long": [
          "Western European Standard Time",
          "Western European Summer Time"
        ],
        "short": [
          "WET",
          "WEST"
        ]
      },
      "Atlantic/Stanley": {
        "long": [
          "Falkland Islands Standard Time",
          "Falkland Islands Summer Time"
        ]
      },
      "Pacific/Fiji": {
        "long": [
          "Fiji Standard Time",
          "Fiji Summer Time"
        ]
      },
      "America/Cayenne": {
        "long": [
          "French Guiana Time",
          "French Guiana Time"
        ]
      },
      "Indian/Kerguelen": {
        "long": [
          "French Southern & Antarctic Time",
          "French Southern & Antarctic Time"
        ]
      },
      "Asia/Bishkek": {
        "long": [
          "Kyrgyzstan Time",
          "Kyrgyzstan Time"
        ]
      },
      "Pacific/Galapagos": {
        "long": [
          "Galapagos Time",
          "Galapagos Time"
        ]
      },
      "Pacific/Gambier": {
        "long": [
          "Gambier Time",
          "Gambier Time"
        ]
      },
      "Pacific/Tarawa": {
        "long": [
          "Gilbert Islands Time",
          "Gilbert Islands Time"
        ]
      },
      "Atlantic/Reykjavik": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Ouagadougou": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Abidjan": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Accra": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Banjul": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Conakry": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Bamako": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Nouakchott": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Atlantic/St_Helena": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Freetown": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Dakar": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "Africa/Lome": {
        "long": [
          "Greenwich Mean Time",
          "Greenwich Mean Time"
        ],
        "short": [
          "GMT",
          "GMT"
        ]
      },
      "America/Scoresbysund": {
        "long": [
          "East Greenland Standard Time",
          "East Greenland Summer Time"
        ]
      },
      "America/Godthab": {
        "long": [
          "West Greenland Standard Time",
          "West Greenland Summer Time"
        ]
      },
      "Asia/Dubai": {
        "long": [
          "Gulf Standard Time",
          "Gulf Standard Time"
        ]
      },
      "Asia/Muscat": {
        "long": [
          "Gulf Standard Time",
          "Gulf Standard Time"
        ]
      },
      "America/Guyana": {
        "long": [
          "Guyana Time",
          "Guyana Time"
        ]
      },
      "Pacific/Honolulu": {
        "long": [
          "Hawaii-Aleutian Standard Time",
          "Hawaii-Aleutian Daylight Time"
        ]
      },
      "Asia/Hong_Kong": {
        "long": [
          "Hong Kong Standard Time",
          "Hong Kong Summer Time"
        ]
      },
      "Asia/Hovd": {
        "long": [
          "Hovd Standard Time",
          "Hovd Summer Time"
        ]
      },
      "Asia/Calcutta": {
        "long": [
          "India Standard Time",
          "India Standard Time"
        ]
      },
      "Asia/Colombo": {
        "long": [
          "Lanka Time",
          "Lanka Time"
        ]
      },
      "Indian/Chagos": {
        "long": [
          "Indian Ocean Time",
          "Indian Ocean Time"
        ]
      },
      "Asia/Bangkok": {
        "long": [
          "Indochina Time",
          "Indochina Time"
        ]
      },
      "Asia/Phnom_Penh": {
        "long": [
          "Indochina Time",
          "Indochina Time"
        ]
      },
      "Asia/Vientiane": {
        "long": [
          "Indochina Time",
          "Indochina Time"
        ]
      },
      "Asia/Makassar": {
        "long": [
          "Central Indonesia Time",
          "Central Indonesia Time"
        ]
      },
      "Asia/Jayapura": {
        "long": [
          "Eastern Indonesia Time",
          "Eastern Indonesia Time"
        ]
      },
      "Asia/Jakarta": {
        "long": [
          "Western Indonesia Time",
          "Western Indonesia Time"
        ]
      },
      "Asia/Tehran": {
        "long": [
          "Iran Standard Time",
          "Iran Daylight Time"
        ]
      },
      "Asia/Irkutsk": {
        "long": [
          "Irkutsk Standard Time",
          "Irkutsk Summer Time"
        ]
      },
      "Asia/Jerusalem": {
        "long": [
          "Israel Standard Time",
          "Israel Daylight Time"
        ]
      },
      "Asia/Tokyo": {
        "long": [
          "Japan Standard Time",
          "Japan Daylight Time"
        ]
      },
      "Asia/Kamchatka": {
        "long": [
          "Petropavlovsk-Kamchatski Standard Time",
          "Petropavlovsk-Kamchatski Summer Time"
        ]
      },
      "Asia/Karachi": {
        "long": [
          "Pakistan Standard Time",
          "Pakistan Summer Time"
        ]
      },
      "Asia/Qyzylorda": {
        "long": [
          "Qyzylorda Standard Time",
          "Qyzylorda Summer Time"
        ]
      },
      "Asia/Seoul": {
        "long": [
          "Korean Standard Time",
          "Korean Daylight Time"
        ]
      },
      "Pacific/Kosrae": {
        "long": [
          "Kosrae Time",
          "Kosrae Time"
        ]
      },
      "Asia/Krasnoyarsk": {
        "long": [
          "Krasnoyarsk Standard Time",
          "Krasnoyarsk Summer Time"
        ]
      },
      "Europe/Samara": {
        "long": [
          "Samara Standard Time",
          "Samara Summer Time"
        ]
      },
      "Pacific/Kiritimati": {
        "long": [
          "Line Islands Time",
          "Line Islands Time"
        ]
      },
      "Australia/Lord_Howe": {
        "long": [
          "Lord Howe Standard Time",
          "Lord Howe Daylight Time"
        ]
      },
      "Asia/Macau": {
        "long": [
          "Macao Standard Time",
          "Macao Summer Time"
        ]
      },
      "Antarctica/Macquarie": {
        "long": [
          "Macquarie Island Time",
          "Macquarie Island Time"
        ]
      },
      "Asia/Magadan": {
        "long": [
          "Magadan Standard Time",
          "Magadan Summer Time"
        ]
      },
      "Indian/Maldives": {
        "long": [
          "Maldives Time",
          "Maldives Time"
        ]
      },
      "Pacific/Marquesas": {
        "long": [
          "Marquesas Time",
          "Marquesas Time"
        ]
      },
      "Pacific/Majuro": {
        "long": [
          "Marshall Islands Time",
          "Marshall Islands Time"
        ]
      },
      "Indian/Mauritius": {
        "long": [
          "Mauritius Standard Time",
          "Mauritius Summer Time"
        ]
      },
      "Antarctica/Mawson": {
        "long": [
          "Mawson Time",
          "Mawson Time"
        ]
      },
      "America/Santa_Isabel": {
        "long": [
          "Northwest Mexico Standard Time",
          "Northwest Mexico Daylight Time"
        ]
      },
      "America/Mazatlan": {
        "long": [
          "Mexican Pacific Standard Time",
          "Mexican Pacific Daylight Time"
        ]
      },
      "Asia/Ulaanbaatar": {
        "long": [
          "Ulaanbaatar Standard Time",
          "Ulaanbaatar Summer Time"
        ]
      },
      "Europe/Moscow": {
        "long": [
          "Moscow Standard Time",
          "Moscow Summer Time"
        ]
      },
      "Asia/Rangoon": {
        "long": [
          "Myanmar Time",
          "Myanmar Time"
        ]
      },
      "Pacific/Nauru": {
        "long": [
          "Nauru Time",
          "Nauru Time"
        ]
      },
      "Asia/Katmandu": {
        "long": [
          "Nepal Time",
          "Nepal Time"
        ]
      },
      "Pacific/Noumea": {
        "long": [
          "New Caledonia Standard Time",
          "New Caledonia Summer Time"
        ]
      },
      "Pacific/Auckland": {
        "long": [
          "New Zealand Standard Time",
          "New Zealand Daylight Time"
        ]
      },
      "Antarctica/McMurdo": {
        "long": [
          "New Zealand Standard Time",
          "New Zealand Daylight Time"
        ]
      },
      "America/St_Johns": {
        "long": [
          "Newfoundland Standard Time",
          "Newfoundland Daylight Time"
        ]
      },
      "Pacific/Niue": {
        "long": [
          "Niue Time",
          "Niue Time"
        ]
      },
      "Pacific/Norfolk": {
        "long": [
          "Norfolk Island Standard Time",
          "Norfolk Island Daylight Time"
        ]
      },
      "America/Noronha": {
        "long": [
          "Fernando de Noronha Standard Time",
          "Fernando de Noronha Summer Time"
        ]
      },
      "Asia/Novosibirsk": {
        "long": [
          "Novosibirsk Standard Time",
          "Novosibirsk Summer Time"
        ]
      },
      "Asia/Omsk": {
        "long": [
          "Omsk Standard Time",
          "Omsk Summer Time"
        ]
      },
      "Pacific/Palau": {
        "long": [
          "Palau Time",
          "Palau Time"
        ]
      },
      "Pacific/Port_Moresby": {
        "long": [
          "Papua New Guinea Time",
          "Papua New Guinea Time"
        ]
      },
      "America/Asuncion": {
        "long": [
          "Paraguay Standard Time",
          "Paraguay Summer Time"
        ]
      },
      "America/Lima": {
        "long": [
          "Peru Standard Time",
          "Peru Summer Time"
        ]
      },
      "Asia/Manila": {
        "long": [
          "Philippine Standard Time",
          "Philippine Summer Time"
        ]
      },
      "Pacific/Enderbury": {
        "long": [
          "Phoenix Islands Time",
          "Phoenix Islands Time"
        ]
      },
      "America/Miquelon": {
        "long": [
          "St Pierre & Miquelon Standard Time",
          "St Pierre & Miquelon Daylight Time"
        ]
      },
      "Pacific/Pitcairn": {
        "long": [
          "Pitcairn Time",
          "Pitcairn Time"
        ]
      },
      "Pacific/Ponape": {
        "long": [
          "Ponape Time",
          "Ponape Time"
        ]
      },
      "Asia/Pyongyang": {
        "long": [
          "Pyongyang Time",
          "Pyongyang Time"
        ]
      },
      "Indian/Reunion": {
        "long": [
          "Réunion Time",
          "Réunion Time"
        ]
      },
      "Antarctica/Rothera": {
        "long": [
          "Rothera Time",
          "Rothera Time"
        ]
      },
      "Asia/Sakhalin": {
        "long": [
          "Sakhalin Standard Time",
          "Sakhalin Summer Time"
        ]
      },
      "Pacific/Pago_Pago": {
        "long": [
          "Samoa Standard Time",
          "Samoa Daylight Time"
        ]
      },
      "Indian/Mahe": {
        "long": [
          "Seychelles Time",
          "Seychelles Time"
        ]
      },
      "Asia/Singapore": {
        "long": [
          "Singapore Standard Time",
          "Singapore Standard Time"
        ]
      },
      "Pacific/Guadalcanal": {
        "long": [
          "Solomon Islands Time",
          "Solomon Islands Time"
        ]
      },
      "Atlantic/South_Georgia": {
        "long": [
          "South Georgia Time",
          "South Georgia Time"
        ]
      },
      "Asia/Yekaterinburg": {
        "long": [
          "Yekaterinburg Standard Time",
          "Yekaterinburg Summer Time"
        ]
      },
      "Antarctica/Syowa": {
        "long": [
          "Syowa Time",
          "Syowa Time"
        ]
      },
      "Pacific/Tahiti": {
        "long": [
          "Tahiti Time",
          "Tahiti Time"
        ]
      },
      "Asia/Taipei": {
        "long": [
          "Taipei Standard Time",
          "Taipei Daylight Time"
        ]
      },
      "Asia/Tashkent": {
        "long": [
          "Uzbekistan Standard Time",
          "Uzbekistan Summer Time"
        ]
      },
      "Pacific/Fakaofo": {
        "long": [
          "Tokelau Time",
          "Tokelau Time"
        ]
      },
      "Pacific/Tongatapu": {
        "long": [
          "Tonga Standard Time",
          "Tonga Summer Time"
        ]
      },
      "Pacific/Truk": {
        "long": [
          "Chuuk Time",
          "Chuuk Time"
        ]
      },
      "Pacific/Funafuti": {
        "long": [
          "Tuvalu Time",
          "Tuvalu Time"
        ]
      },
      "America/Montevideo": {
        "long": [
          "Uruguay Standard Time",
          "Uruguay Summer Time"
        ]
      },
      "Pacific/Efate": {
        "long": [
          "Vanuatu Standard Time",
          "Vanuatu Summer Time"
        ]
      },
      "America/Caracas": {
        "long": [
          "Venezuela Time",
          "Venezuela Time"
        ]
      },
      "Asia/Vladivostok": {
        "long": [
          "Vladivostok Standard Time",
          "Vladivostok Summer Time"
        ]
      },
      "Europe/Volgograd": {
        "long": [
          "Volgograd Standard Time",
          "Volgograd Summer Time"
        ]
      },
      "Antarctica/Vostok": {
        "long": [
          "Vostok Time",
          "Vostok Time"
        ]
      },
      "Pacific/Wake": {
        "long": [
          "Wake Island Time",
          "Wake Island Time"
        ]
      },
      "Pacific/Wallis": {
        "long": [
          "Wallis & Futuna Time",
          "Wallis & Futuna Time"
        ]
      },
      "Asia/Yakutsk": {
        "long": [
          "Yakutsk Standard Time",
          "Yakutsk Summer Time"
        ]
      },
      "America/Whitehorse": {
        "long": [
          "Yukon Time",
          "Yukon Time"
        ]
      },
      "UTC": {
        "long": [
          "Coordinated Universal Time",
          "Coordinated Universal Time"
        ],
        "short": [
          "UTC",
          "UTC"
        ]
      }
    },
    "gmtFormat": "GMT{0}",
    "hourFormat": "+HH:mm;-HH:mm",
    "dateFormat": {
      "full": "EEEE, d MMMM y",
      "long": "d MMMM y",
      "medium": "d MMM y",
      "short": "dd/MM/y"
    },
    "timeFormat": {
      "full": "HH:mm:ss zzzz",
      "long": "HH:mm:ss z",
      "medium": "HH:mm:ss",
      "short": "HH:mm"
    },
    "dateTimeFormat": {
      "full": "{1} 'at' {0}",
      "long": "{1} 'at' {0}",
      "medium": "{1}, {0}",
      "short": "{1}, {0}"
    },
    "formats": {
      "gregory": {
        "Bh": "h B",
        "Bhm": "h:mm B",
        "Bhms": "h:mm:ss B",
        "d": "d",
        "E": "ccc",
        "EBhm": "E h:mm B",
        "EBhms": "E h:mm:ss B",
        "Ed": "E d",
        "Ehm": "E h:mm a",
        "EHm": "E HH:mm",
        "Ehms": "E h:mm:ss a",
        "EHms": "E HH:mm:ss",
        "Gy": "y G",
        "GyMd": "d/M/y GGGGG",
        "GyMMM": "MMM y G",
        "GyMMMd": "d MMM y G",
        "GyMMMEd": "E, d MMM y G",
        "h": "h a",
        "H": "HH",
        "hm": "h:mm a",
        "Hm": "HH:mm",
        "hms": "h:mm:ss a",
        "Hms": "HH:mm:ss",
        "hmsv": "h:mm:ss a v",
        "Hmsv": "HH:mm:ss v",
        "hmv": "h:mm a v",
        "Hmv": "HH:mm v",
        "M": "L",
        "Md": "dd/MM",
        "MEd": "E, dd/MM",
        "MMdd": "dd/MM",
        "MMM": "LLL",
        "MMMd": "d MMM",
        "MMMEd": "E, d MMM",
        "MMMMd": "d MMMM",
        "ms": "mm:ss",
        "y": "y",
        "yM": "MM/y",
        "yMd": "dd/MM/y",
        "yMEd": "E, dd/MM/y",
        "yMMM": "MMM y",
        "yMMMd": "d MMM y",
        "yMMMEd": "E, d MMM y",
        "yMMMM": "MMMM y",
        "EEEE, d MMMM y": "EEEE, d MMMM y",
        "d MMMM y": "d MMMM y",
        "d MMM y": "d MMM y",
        "dd/MM/y": "dd/MM/y",
        "HH:mm:ss zzzz": "HH:mm:ss zzzz",
        "HH:mm:ss z": "HH:mm:ss z",
        "HH:mm:ss": "HH:mm:ss",
        "HH:mm": "HH:mm",
        "EEEE, d MMMM y 'at' HH:mm:ss zzzz": "EEEE, d MMMM y 'at' HH:mm:ss zzzz",
        "d MMMM y 'at' HH:mm:ss zzzz": "d MMMM y 'at' HH:mm:ss zzzz",
        "d MMM y, HH:mm:ss zzzz": "d MMM y, HH:mm:ss zzzz",
        "dd/MM/y, HH:mm:ss zzzz": "dd/MM/y, HH:mm:ss zzzz",
        "d, HH:mm:ss zzzz": "d, HH:mm:ss zzzz",
        "E, HH:mm:ss zzzz": "ccc, HH:mm:ss zzzz",
        "Ed, HH:mm:ss zzzz": "E d, HH:mm:ss zzzz",
        "Gy, HH:mm:ss zzzz": "y G, HH:mm:ss zzzz",
        "GyMd, HH:mm:ss zzzz": "d/M/y GGGGG, HH:mm:ss zzzz",
        "GyMMM, HH:mm:ss zzzz": "MMM y G, HH:mm:ss zzzz",
        "GyMMMd, HH:mm:ss zzzz": "d MMM y G, HH:mm:ss zzzz",
        "GyMMMEd, HH:mm:ss zzzz": "E, d MMM y G, HH:mm:ss zzzz",
        "M, HH:mm:ss zzzz": "L, HH:mm:ss zzzz",
        "Md, HH:mm:ss zzzz": "dd/MM, HH:mm:ss zzzz",
        "MEd, HH:mm:ss zzzz": "E, dd/MM, HH:mm:ss zzzz",
        "MMdd, HH:mm:ss zzzz": "dd/MM, HH:mm:ss zzzz",
        "MMM, HH:mm:ss zzzz": "LLL, HH:mm:ss zzzz",
        "MMMd, HH:mm:ss zzzz": "d MMM, HH:mm:ss zzzz",
        "MMMEd, HH:mm:ss zzzz": "E, d MMM, HH:mm:ss zzzz",
        "MMMMd 'at' HH:mm:ss zzzz": "d MMMM 'at' HH:mm:ss zzzz",
        "y, HH:mm:ss zzzz": "y, HH:mm:ss zzzz",
        "yM, HH:mm:ss zzzz": "MM/y, HH:mm:ss zzzz",
        "yMd, HH:mm:ss zzzz": "dd/MM/y, HH:mm:ss zzzz",
        "yMEd, HH:mm:ss zzzz": "E, dd/MM/y, HH:mm:ss zzzz",
        "yMMM, HH:mm:ss zzzz": "MMM y, HH:mm:ss zzzz",
        "yMMMd, HH:mm:ss zzzz": "d MMM y, HH:mm:ss zzzz",
        "yMMMEd, HH:mm:ss zzzz": "E, d MMM y, HH:mm:ss zzzz",
        "yMMMM 'at' HH:mm:ss zzzz": "MMMM y 'at' HH:mm:ss zzzz",
        "EEEE, d MMMM y 'at' HH:mm:ss z": "EEEE, d MMMM y 'at' HH:mm:ss z",
        "d MMMM y 'at' HH:mm:ss z": "d MMMM y 'at' HH:mm:ss z",
        "d MMM y, HH:mm:ss z": "d MMM y, HH:mm:ss z",
        "dd/MM/y, HH:mm:ss z": "dd/MM/y, HH:mm:ss z",
        "d, HH:mm:ss z": "d, HH:mm:ss z",
        "E, HH:mm:ss z": "ccc, HH:mm:ss z",
        "Ed, HH:mm:ss z": "E d, HH:mm:ss z",
        "Gy, HH:mm:ss z": "y G, HH:mm:ss z",
        "GyMd, HH:mm:ss z": "d/M/y GGGGG, HH:mm:ss z",
        "GyMMM, HH:mm:ss z": "MMM y G, HH:mm:ss z",
        "GyMMMd, HH:mm:ss z": "d MMM y G, HH:mm:ss z",
        "GyMMMEd, HH:mm:ss z": "E, d MMM y G, HH:mm:ss z",
        "M, HH:mm:ss z": "L, HH:mm:ss z",
        "Md, HH:mm:ss z": "dd/MM, HH:mm:ss z",
        "MEd, HH:mm:ss z": "E, dd/MM, HH:mm:ss z",
        "MMdd, HH:mm:ss z": "dd/MM, HH:mm:ss z",
        "MMM, HH:mm:ss z": "LLL, HH:mm:ss z",
        "MMMd, HH:mm:ss z": "d MMM, HH:mm:ss z",
        "MMMEd, HH:mm:ss z": "E, d MMM, HH:mm:ss z",
        "MMMMd 'at' HH:mm:ss z": "d MMMM 'at' HH:mm:ss z",
        "y, HH:mm:ss z": "y, HH:mm:ss z",
        "yM, HH:mm:ss z": "MM/y, HH:mm:ss z",
        "yMd, HH:mm:ss z": "dd/MM/y, HH:mm:ss z",
        "yMEd, HH:mm:ss z": "E, dd/MM/y, HH:mm:ss z",
        "yMMM, HH:mm:ss z": "MMM y, HH:mm:ss z",
        "yMMMd, HH:mm:ss z": "d MMM y, HH:mm:ss z",
        "yMMMEd, HH:mm:ss z": "E, d MMM y, HH:mm:ss z",
        "yMMMM 'at' HH:mm:ss z": "MMMM y 'at' HH:mm:ss z",
        "EEEE, d MMMM y 'at' HH:mm:ss": "EEEE, d MMMM y 'at' HH:mm:ss",
        "d MMMM y 'at' HH:mm:ss": "d MMMM y 'at' HH:mm:ss",
        "d MMM y, HH:mm:ss": "d MMM y, HH:mm:ss",
        "dd/MM/y, HH:mm:ss": "dd/MM/y, HH:mm:ss",
        "d, HH:mm:ss": "d, HH:mm:ss",
        "E, HH:mm:ss": "ccc, HH:mm:ss",
        "Ed, HH:mm:ss": "E d, HH:mm:ss",
        "Gy, HH:mm:ss": "y G, HH:mm:ss",
        "GyMd, HH:mm:ss": "d/M/y GGGGG, HH:mm:ss",
        "GyMMM, HH:mm:ss": "MMM y G, HH:mm:ss",
        "GyMMMd, HH:mm:ss": "d MMM y G, HH:mm:ss",
        "GyMMMEd, HH:mm:ss": "E, d MMM y G, HH:mm:ss",
        "M, HH:mm:ss": "L, HH:mm:ss",
        "Md, HH:mm:ss": "dd/MM, HH:mm:ss",
        "MEd, HH:mm:ss": "E, dd/MM, HH:mm:ss",
        "MMdd, HH:mm:ss": "dd/MM, HH:mm:ss",
        "MMM, HH:mm:ss": "LLL, HH:mm:ss",
        "MMMd, HH:mm:ss": "d MMM, HH:mm:ss",
        "MMMEd, HH:mm:ss": "E, d MMM, HH:mm:ss",
        "MMMMd 'at' HH:mm:ss": "d MMMM 'at' HH:mm:ss",
        "y, HH:mm:ss": "y, HH:mm:ss",
        "yM, HH:mm:ss": "MM/y, HH:mm:ss",
        "yMd, HH:mm:ss": "dd/MM/y, HH:mm:ss",
        "yMEd, HH:mm:ss": "E, dd/MM/y, HH:mm:ss",
        "yMMM, HH:mm:ss": "MMM y, HH:mm:ss",
        "yMMMd, HH:mm:ss": "d MMM y, HH:mm:ss",
        "yMMMEd, HH:mm:ss": "E, d MMM y, HH:mm:ss",
        "yMMMM 'at' HH:mm:ss": "MMMM y 'at' HH:mm:ss",
        "EEEE, d MMMM y 'at' HH:mm": "EEEE, d MMMM y 'at' HH:mm",
        "d MMMM y 'at' HH:mm": "d MMMM y 'at' HH:mm",
        "d MMM y, HH:mm": "d MMM y, HH:mm",
        "dd/MM/y, HH:mm": "dd/MM/y, HH:mm",
        "d, HH:mm": "d, HH:mm",
        "E, HH:mm": "ccc, HH:mm",
        "Ed, HH:mm": "E d, HH:mm",
        "Gy, HH:mm": "y G, HH:mm",
        "GyMd, HH:mm": "d/M/y GGGGG, HH:mm",
        "GyMMM, HH:mm": "MMM y G, HH:mm",
        "GyMMMd, HH:mm": "d MMM y G, HH:mm",
        "GyMMMEd, HH:mm": "E, d MMM y G, HH:mm",
        "M, HH:mm": "L, HH:mm",
        "Md, HH:mm": "dd/MM, HH:mm",
        "MEd, HH:mm": "E, dd/MM, HH:mm",
        "MMdd, HH:mm": "dd/MM, HH:mm",
        "MMM, HH:mm": "LLL, HH:mm",
        "MMMd, HH:mm": "d MMM, HH:mm",
        "MMMEd, HH:mm": "E, d MMM, HH:mm",
        "MMMMd 'at' HH:mm": "d MMMM 'at' HH:mm",
        "y, HH:mm": "y, HH:mm",
        "yM, HH:mm": "MM/y, HH:mm",
        "yMd, HH:mm": "dd/MM/y, HH:mm",
        "yMEd, HH:mm": "E, dd/MM/y, HH:mm",
        "yMMM, HH:mm": "MMM y, HH:mm",
        "yMMMd, HH:mm": "d MMM y, HH:mm",
        "yMMMEd, HH:mm": "E, d MMM y, HH:mm",
        "yMMMM 'at' HH:mm": "MMMM y 'at' HH:mm",
        "EEEE, d MMMM y 'at' Bh": "EEEE, d MMMM y 'at' h B",
        "d MMMM y 'at' Bh": "d MMMM y 'at' h B",
        "d MMM y, Bh": "d MMM y, h B",
        "dd/MM/y, Bh": "dd/MM/y, h B",
        "d, Bh": "d, h B",
        "E, Bh": "ccc, h B",
        "Ed, Bh": "E d, h B",
        "Gy, Bh": "y G, h B",
        "GyMd, Bh": "d/M/y GGGGG, h B",
        "GyMMM, Bh": "MMM y G, h B",
        "GyMMMd, Bh": "d MMM y G, h B",
        "GyMMMEd, Bh": "E, d MMM y G, h B",
        "M, Bh": "L, h B",
        "Md, Bh": "dd/MM, h B",
        "MEd, Bh": "E, dd/MM, h B",
        "MMdd, Bh": "dd/MM, h B",
        "MMM, Bh": "LLL, h B",
        "MMMd, Bh": "d MMM, h B",
        "MMMEd, Bh": "E, d MMM, h B",
        "MMMMd 'at' Bh": "d MMMM 'at' h B",
        "y, Bh": "y, h B",
        "yM, Bh": "MM/y, h B",
        "yMd, Bh": "dd/MM/y, h B",
        "yMEd, Bh": "E, dd/MM/y, h B",
        "yMMM, Bh": "MMM y, h B",
        "yMMMd, Bh": "d MMM y, h B",
        "yMMMEd, Bh": "E, d MMM y, h B",
        "yMMMM 'at' Bh": "MMMM y 'at' h B",
        "EEEE, d MMMM y 'at' Bhm": "EEEE, d MMMM y 'at' h:mm B",
        "d MMMM y 'at' Bhm": "d MMMM y 'at' h:mm B",
        "d MMM y, Bhm": "d MMM y, h:mm B",
        "dd/MM/y, Bhm": "dd/MM/y, h:mm B",
        "d, Bhm": "d, h:mm B",
        "E, Bhm": "ccc, h:mm B",
        "Ed, Bhm": "E d, h:mm B",
        "Gy, Bhm": "y G, h:mm B",
        "GyMd, Bhm": "d/M/y GGGGG, h:mm B",
        "GyMMM, Bhm": "MMM y G, h:mm B",
        "GyMMMd, Bhm": "d MMM y G, h:mm B",
        "GyMMMEd, Bhm": "E, d MMM y G, h:mm B",
        "M, Bhm": "L, h:mm B",
        "Md, Bhm": "dd/MM, h:mm B",
        "MEd, Bhm": "E, dd/MM, h:mm B",
        "MMdd, Bhm": "dd/MM, h:mm B",
        "MMM, Bhm": "LLL, h:mm B",
        "MMMd, Bhm": "d MMM, h:mm B",
        "MMMEd, Bhm": "E, d MMM, h:mm B",
        "MMMMd 'at' Bhm": "d MMMM 'at' h:mm B",
        "y, Bhm": "y, h:mm B",
        "yM, Bhm": "MM/y, h:mm B",
        "yMd, Bhm": "dd/MM/y, h:mm B",
        "yMEd, Bhm": "E, dd/MM/y, h:mm B",
        "yMMM, Bhm": "MMM y, h:mm B",
        "yMMMd, Bhm": "d MMM y, h:mm B",
        "yMMMEd, Bhm": "E, d MMM y, h:mm B",
        "yMMMM 'at' Bhm": "MMMM y 'at' h:mm B",
        "EEEE, d MMMM y 'at' Bhms": "EEEE, d MMMM y 'at' h:mm:ss B",
        "d MMMM y 'at' Bhms": "d MMMM y 'at' h:mm:ss B",
        "d MMM y, Bhms": "d MMM y, h:mm:ss B",
        "dd/MM/y, Bhms": "dd/MM/y, h:mm:ss B",
        "d, Bhms": "d, h:mm:ss B",
        "E, Bhms": "ccc, h:mm:ss B",
        "Ed, Bhms": "E d, h:mm:ss B",
        "Gy, Bhms": "y G, h:mm:ss B",
        "GyMd, Bhms": "d/M/y GGGGG, h:mm:ss B",
        "GyMMM, Bhms": "MMM y G, h:mm:ss B",
        "GyMMMd, Bhms": "d MMM y G, h:mm:ss B",
        "GyMMMEd, Bhms": "E, d MMM y G, h:mm:ss B",
        "M, Bhms": "L, h:mm:ss B",
        "Md, Bhms": "dd/MM, h:mm:ss B",
        "MEd, Bhms": "E, dd/MM, h:mm:ss B",
        "MMdd, Bhms": "dd/MM, h:mm:ss B",
        "MMM, Bhms": "LLL, h:mm:ss B",
        "MMMd, Bhms": "d MMM, h:mm:ss B",
        "MMMEd, Bhms": "E, d MMM, h:mm:ss B",
        "MMMMd 'at' Bhms": "d MMMM 'at' h:mm:ss B",
        "y, Bhms": "y, h:mm:ss B",
        "yM, Bhms": "MM/y, h:mm:ss B",
        "yMd, Bhms": "dd/MM/y, h:mm:ss B",
        "yMEd, Bhms": "E, dd/MM/y, h:mm:ss B",
        "yMMM, Bhms": "MMM y, h:mm:ss B",
        "yMMMd, Bhms": "d MMM y, h:mm:ss B",
        "yMMMEd, Bhms": "E, d MMM y, h:mm:ss B",
        "yMMMM 'at' Bhms": "MMMM y 'at' h:mm:ss B",
        "EEEE, d MMMM y 'at' h": "EEEE, d MMMM y 'at' h a",
        "d MMMM y 'at' h": "d MMMM y 'at' h a",
        "d MMM y, h": "d MMM y, h a",
        "dd/MM/y, h": "dd/MM/y, h a",
        "d, h": "d, h a",
        "E, h": "ccc, h a",
        "Ed, h": "E d, h a",
        "Gy, h": "y G, h a",
        "GyMd, h": "d/M/y GGGGG, h a",
        "GyMMM, h": "MMM y G, h a",
        "GyMMMd, h": "d MMM y G, h a",
        "GyMMMEd, h": "E, d MMM y G, h a",
        "M, h": "L, h a",
        "Md, h": "dd/MM, h a",
        "MEd, h": "E, dd/MM, h a",
        "MMdd, h": "dd/MM, h a",
        "MMM, h": "LLL, h a",
        "MMMd, h": "d MMM, h a",
        "MMMEd, h": "E, d MMM, h a",
        "MMMMd 'at' h": "d MMMM 'at' h a",
        "y, h": "y, h a",
        "yM, h": "MM/y, h a",
        "yMd, h": "dd/MM/y, h a",
        "yMEd, h": "E, dd/MM/y, h a",
        "yMMM, h": "MMM y, h a",
        "yMMMd, h": "d MMM y, h a",
        "yMMMEd, h": "E, d MMM y, h a",
        "yMMMM 'at' h": "MMMM y 'at' h a",
        "EEEE, d MMMM y 'at' H": "EEEE, d MMMM y 'at' HH",
        "d MMMM y 'at' H": "d MMMM y 'at' HH",
        "d MMM y, H": "d MMM y, HH",
        "dd/MM/y, H": "dd/MM/y, HH",
        "d, H": "d, HH",
        "E, H": "ccc, HH",
        "Ed, H": "E d, HH",
        "Gy, H": "y G, HH",
        "GyMd, H": "d/M/y GGGGG, HH",
        "GyMMM, H": "MMM y G, HH",
        "GyMMMd, H": "d MMM y G, HH",
        "GyMMMEd, H": "E, d MMM y G, HH",
        "M, H": "L, HH",
        "Md, H": "dd/MM, HH",
        "MEd, H": "E, dd/MM, HH",
        "MMdd, H": "dd/MM, HH",
        "MMM, H": "LLL, HH",
        "MMMd, H": "d MMM, HH",
        "MMMEd, H": "E, d MMM, HH",
        "MMMMd 'at' H": "d MMMM 'at' HH",
        "y, H": "y, HH",
        "yM, H": "MM/y, HH",
        "yMd, H": "dd/MM/y, HH",
        "yMEd, H": "E, dd/MM/y, HH",
        "yMMM, H": "MMM y, HH",
        "yMMMd, H": "d MMM y, HH",
        "yMMMEd, H": "E, d MMM y, HH",
        "yMMMM 'at' H": "MMMM y 'at' HH",
        "EEEE, d MMMM y 'at' hm": "EEEE, d MMMM y 'at' h:mm a",
        "d MMMM y 'at' hm": "d MMMM y 'at' h:mm a",
        "d MMM y, hm": "d MMM y, h:mm a",
        "dd/MM/y, hm": "dd/MM/y, h:mm a",
        "d, hm": "d, h:mm a",
        "E, hm": "ccc, h:mm a",
        "Ed, hm": "E d, h:mm a",
        "Gy, hm": "y G, h:mm a",
        "GyMd, hm": "d/M/y GGGGG, h:mm a",
        "GyMMM, hm": "MMM y G, h:mm a",
        "GyMMMd, hm": "d MMM y G, h:mm a",
        "GyMMMEd, hm": "E, d MMM y G, h:mm a",
        "M, hm": "L, h:mm a",
        "Md, hm": "dd/MM, h:mm a",
        "MEd, hm": "E, dd/MM, h:mm a",
        "MMdd, hm": "dd/MM, h:mm a",
        "MMM, hm": "LLL, h:mm a",
        "MMMd, hm": "d MMM, h:mm a",
        "MMMEd, hm": "E, d MMM, h:mm a",
        "MMMMd 'at' hm": "d MMMM 'at' h:mm a",
        "y, hm": "y, h:mm a",
        "yM, hm": "MM/y, h:mm a",
        "yMd, hm": "dd/MM/y, h:mm a",
        "yMEd, hm": "E, dd/MM/y, h:mm a",
        "yMMM, hm": "MMM y, h:mm a",
        "yMMMd, hm": "d MMM y, h:mm a",
        "yMMMEd, hm": "E, d MMM y, h:mm a",
        "yMMMM 'at' hm": "MMMM y 'at' h:mm a",
        "EEEE, d MMMM y 'at' Hm": "EEEE, d MMMM y 'at' HH:mm",
        "d MMMM y 'at' Hm": "d MMMM y 'at' HH:mm",
        "d MMM y, Hm": "d MMM y, HH:mm",
        "dd/MM/y, Hm": "dd/MM/y, HH:mm",
        "d, Hm": "d, HH:mm",
        "E, Hm": "ccc, HH:mm",
        "Ed, Hm": "E d, HH:mm",
        "Gy, Hm": "y G, HH:mm",
        "GyMd, Hm": "d/M/y GGGGG, HH:mm",
        "GyMMM, Hm": "MMM y G, HH:mm",
        "GyMMMd, Hm": "d MMM y G, HH:mm",
        "GyMMMEd, Hm": "E, d MMM y G, HH:mm",
        "M, Hm": "L, HH:mm",
        "Md, Hm": "dd/MM, HH:mm",
        "MEd, Hm": "E, dd/MM, HH:mm",
        "MMdd, Hm": "dd/MM, HH:mm",
        "MMM, Hm": "LLL, HH:mm",
        "MMMd, Hm": "d MMM, HH:mm",
        "MMMEd, Hm": "E, d MMM, HH:mm",
        "MMMMd 'at' Hm": "d MMMM 'at' HH:mm",
        "y, Hm": "y, HH:mm",
        "yM, Hm": "MM/y, HH:mm",
        "yMd, Hm": "dd/MM/y, HH:mm",
        "yMEd, Hm": "E, dd/MM/y, HH:mm",
        "yMMM, Hm": "MMM y, HH:mm",
        "yMMMd, Hm": "d MMM y, HH:mm",
        "yMMMEd, Hm": "E, d MMM y, HH:mm",
        "yMMMM 'at' Hm": "MMMM y 'at' HH:mm",
        "EEEE, d MMMM y 'at' hms": "EEEE, d MMMM y 'at' h:mm:ss a",
        "d MMMM y 'at' hms": "d MMMM y 'at' h:mm:ss a",
        "d MMM y, hms": "d MMM y, h:mm:ss a",
        "dd/MM/y, hms": "dd/MM/y, h:mm:ss a",
        "d, hms": "d, h:mm:ss a",
        "E, hms": "ccc, h:mm:ss a",
        "Ed, hms": "E d, h:mm:ss a",
        "Gy, hms": "y G, h:mm:ss a",
        "GyMd, hms": "d/M/y GGGGG, h:mm:ss a",
        "GyMMM, hms": "MMM y G, h:mm:ss a",
        "GyMMMd, hms": "d MMM y G, h:mm:ss a",
        "GyMMMEd, hms": "E, d MMM y G, h:mm:ss a",
        "M, hms": "L, h:mm:ss a",
        "Md, hms": "dd/MM, h:mm:ss a",
        "MEd, hms": "E, dd/MM, h:mm:ss a",
        "MMdd, hms": "dd/MM, h:mm:ss a",
        "MMM, hms": "LLL, h:mm:ss a",
        "MMMd, hms": "d MMM, h:mm:ss a",
        "MMMEd, hms": "E, d MMM, h:mm:ss a",
        "MMMMd 'at' hms": "d MMMM 'at' h:mm:ss a",
        "y, hms": "y, h:mm:ss a",
        "yM, hms": "MM/y, h:mm:ss a",
        "yMd, hms": "dd/MM/y, h:mm:ss a",
        "yMEd, hms": "E, dd/MM/y, h:mm:ss a",
        "yMMM, hms": "MMM y, h:mm:ss a",
        "yMMMd, hms": "d MMM y, h:mm:ss a",
        "yMMMEd, hms": "E, d MMM y, h:mm:ss a",
        "yMMMM 'at' hms": "MMMM y 'at' h:mm:ss a",
        "EEEE, d MMMM y 'at' Hms": "EEEE, d MMMM y 'at' HH:mm:ss",
        "d MMMM y 'at' Hms": "d MMMM y 'at' HH:mm:ss",
        "d MMM y, Hms": "d MMM y, HH:mm:ss",
        "dd/MM/y, Hms": "dd/MM/y, HH:mm:ss",
        "d, Hms": "d, HH:mm:ss",
        "E, Hms": "ccc, HH:mm:ss",
        "Ed, Hms": "E d, HH:mm:ss",
        "Gy, Hms": "y G, HH:mm:ss",
        "GyMd, Hms": "d/M/y GGGGG, HH:mm:ss",
        "GyMMM, Hms": "MMM y G, HH:mm:ss",
        "GyMMMd, Hms": "d MMM y G, HH:mm:ss",
        "GyMMMEd, Hms": "E, d MMM y G, HH:mm:ss",
        "M, Hms": "L, HH:mm:ss",
        "Md, Hms": "dd/MM, HH:mm:ss",
        "MEd, Hms": "E, dd/MM, HH:mm:ss",
        "MMdd, Hms": "dd/MM, HH:mm:ss",
        "MMM, Hms": "LLL, HH:mm:ss",
        "MMMd, Hms": "d MMM, HH:mm:ss",
        "MMMEd, Hms": "E, d MMM, HH:mm:ss",
        "MMMMd 'at' Hms": "d MMMM 'at' HH:mm:ss",
        "y, Hms": "y, HH:mm:ss",
        "yM, Hms": "MM/y, HH:mm:ss",
        "yMd, Hms": "dd/MM/y, HH:mm:ss",
        "yMEd, Hms": "E, dd/MM/y, HH:mm:ss",
        "yMMM, Hms": "MMM y, HH:mm:ss",
        "yMMMd, Hms": "d MMM y, HH:mm:ss",
        "yMMMEd, Hms": "E, d MMM y, HH:mm:ss",
        "yMMMM 'at' Hms": "MMMM y 'at' HH:mm:ss",
        "EEEE, d MMMM y 'at' hmsv": "EEEE, d MMMM y 'at' h:mm:ss a v",
        "d MMMM y 'at' hmsv": "d MMMM y 'at' h:mm:ss a v",
        "d MMM y, hmsv": "d MMM y, h:mm:ss a v",
        "dd/MM/y, hmsv": "dd/MM/y, h:mm:ss a v",
        "d, hmsv": "d, h:mm:ss a v",
        "E, hmsv": "ccc, h:mm:ss a v",
        "Ed, hmsv": "E d, h:mm:ss a v",
        "Gy, hmsv": "y G, h:mm:ss a v",
        "GyMd, hmsv": "d/M/y GGGGG, h:mm:ss a v",
        "GyMMM, hmsv": "MMM y G, h:mm:ss a v",
        "GyMMMd, hmsv": "d MMM y G, h:mm:ss a v",
        "GyMMMEd, hmsv": "E, d MMM y G, h:mm:ss a v",
        "M, hmsv": "L, h:mm:ss a v",
        "Md, hmsv": "dd/MM, h:mm:ss a v",
        "MEd, hmsv": "E, dd/MM, h:mm:ss a v",
        "MMdd, hmsv": "dd/MM, h:mm:ss a v",
        "MMM, hmsv": "LLL, h:mm:ss a v",
        "MMMd, hmsv": "d MMM, h:mm:ss a v",
        "MMMEd, hmsv": "E, d MMM, h:mm:ss a v",
        "MMMMd 'at' hmsv": "d MMMM 'at' h:mm:ss a v",
        "y, hmsv": "y, h:mm:ss a v",
        "yM, hmsv": "MM/y, h:mm:ss a v",
        "yMd, hmsv": "dd/MM/y, h:mm:ss a v",
        "yMEd, hmsv": "E, dd/MM/y, h:mm:ss a v",
        "yMMM, hmsv": "MMM y, h:mm:ss a v",
        "yMMMd, hmsv": "d MMM y, h:mm:ss a v",
        "yMMMEd, hmsv": "E, d MMM y, h:mm:ss a v",
        "yMMMM 'at' hmsv": "MMMM y 'at' h:mm:ss a v",
        "EEEE, d MMMM y 'at' Hmsv": "EEEE, d MMMM y 'at' HH:mm:ss v",
        "d MMMM y 'at' Hmsv": "d MMMM y 'at' HH:mm:ss v",
        "d MMM y, Hmsv": "d MMM y, HH:mm:ss v",
        "dd/MM/y, Hmsv": "dd/MM/y, HH:mm:ss v",
        "d, Hmsv": "d, HH:mm:ss v",
        "E, Hmsv": "ccc, HH:mm:ss v",
        "Ed, Hmsv": "E d, HH:mm:ss v",
        "Gy, Hmsv": "y G, HH:mm:ss v",
        "GyMd, Hmsv": "d/M/y GGGGG, HH:mm:ss v",
        "GyMMM, Hmsv": "MMM y G, HH:mm:ss v",
        "GyMMMd, Hmsv": "d MMM y G, HH:mm:ss v",
        "GyMMMEd, Hmsv": "E, d MMM y G, HH:mm:ss v",
        "M, Hmsv": "L, HH:mm:ss v",
        "Md, Hmsv": "dd/MM, HH:mm:ss v",
        "MEd, Hmsv": "E, dd/MM, HH:mm:ss v",
        "MMdd, Hmsv": "dd/MM, HH:mm:ss v",
        "MMM, Hmsv": "LLL, HH:mm:ss v",
        "MMMd, Hmsv": "d MMM, HH:mm:ss v",
        "MMMEd, Hmsv": "E, d MMM, HH:mm:ss v",
        "MMMMd 'at' Hmsv": "d MMMM 'at' HH:mm:ss v",
        "y, Hmsv": "y, HH:mm:ss v",
        "yM, Hmsv": "MM/y, HH:mm:ss v",
        "yMd, Hmsv": "dd/MM/y, HH:mm:ss v",
        "yMEd, Hmsv": "E, dd/MM/y, HH:mm:ss v",
        "yMMM, Hmsv": "MMM y, HH:mm:ss v",
        "yMMMd, Hmsv": "d MMM y, HH:mm:ss v",
        "yMMMEd, Hmsv": "E, d MMM y, HH:mm:ss v",
        "yMMMM 'at' Hmsv": "MMMM y 'at' HH:mm:ss v",
        "EEEE, d MMMM y 'at' hmv": "EEEE, d MMMM y 'at' h:mm a v",
        "d MMMM y 'at' hmv": "d MMMM y 'at' h:mm a v",
        "d MMM y, hmv": "d MMM y, h:mm a v",
        "dd/MM/y, hmv": "dd/MM/y, h:mm a v",
        "d, hmv": "d, h:mm a v",
        "E, hmv": "ccc, h:mm a v",
        "Ed, hmv": "E d, h:mm a v",
        "Gy, hmv": "y G, h:mm a v",
        "GyMd, hmv": "d/M/y GGGGG, h:mm a v",
        "GyMMM, hmv": "MMM y G, h:mm a v",
        "GyMMMd, hmv": "d MMM y G, h:mm a v",
        "GyMMMEd, hmv": "E, d MMM y G, h:mm a v",
        "M, hmv": "L, h:mm a v",
        "Md, hmv": "dd/MM, h:mm a v",
        "MEd, hmv": "E, dd/MM, h:mm a v",
        "MMdd, hmv": "dd/MM, h:mm a v",
        "MMM, hmv": "LLL, h:mm a v",
        "MMMd, hmv": "d MMM, h:mm a v",
        "MMMEd, hmv": "E, d MMM, h:mm a v",
        "MMMMd 'at' hmv": "d MMMM 'at' h:mm a v",
        "y, hmv": "y, h:mm a v",
        "yM, hmv": "MM/y, h:mm a v",
        "yMd, hmv": "dd/MM/y, h:mm a v",
        "yMEd, hmv": "E, dd/MM/y, h:mm a v",
        "yMMM, hmv": "MMM y, h:mm a v",
        "yMMMd, hmv": "d MMM y, h:mm a v",
        "yMMMEd, hmv": "E, d MMM y, h:mm a v",
        "yMMMM 'at' hmv": "MMMM y 'at' h:mm a v",
        "EEEE, d MMMM y 'at' Hmv": "EEEE, d MMMM y 'at' HH:mm v",
        "d MMMM y 'at' Hmv": "d MMMM y 'at' HH:mm v",
        "d MMM y, Hmv": "d MMM y, HH:mm v",
        "dd/MM/y, Hmv": "dd/MM/y, HH:mm v",
        "d, Hmv": "d, HH:mm v",
        "E, Hmv": "ccc, HH:mm v",
        "Ed, Hmv": "E d, HH:mm v",
        "Gy, Hmv": "y G, HH:mm v",
        "GyMd, Hmv": "d/M/y GGGGG, HH:mm v",
        "GyMMM, Hmv": "MMM y G, HH:mm v",
        "GyMMMd, Hmv": "d MMM y G, HH:mm v",
        "GyMMMEd, Hmv": "E, d MMM y G, HH:mm v",
        "M, Hmv": "L, HH:mm v",
        "Md, Hmv": "dd/MM, HH:mm v",
        "MEd, Hmv": "E, dd/MM, HH:mm v",
        "MMdd, Hmv": "dd/MM, HH:mm v",
        "MMM, Hmv": "LLL, HH:mm v",
        "MMMd, Hmv": "d MMM, HH:mm v",
        "MMMEd, Hmv": "E, d MMM, HH:mm v",
        "MMMMd 'at' Hmv": "d MMMM 'at' HH:mm v",
        "y, Hmv": "y, HH:mm v",
        "yM, Hmv": "MM/y, HH:mm v",
        "yMd, Hmv": "dd/MM/y, HH:mm v",
        "yMEd, Hmv": "E, dd/MM/y, HH:mm v",
        "yMMM, Hmv": "MMM y, HH:mm v",
        "yMMMd, Hmv": "d MMM y, HH:mm v",
        "yMMMEd, Hmv": "E, d MMM y, HH:mm v",
        "yMMMM 'at' Hmv": "MMMM y 'at' HH:mm v",
        "EEEE, d MMMM y 'at' ms": "EEEE, d MMMM y 'at' mm:ss",
        "d MMMM y 'at' ms": "d MMMM y 'at' mm:ss",
        "d MMM y, ms": "d MMM y, mm:ss",
        "dd/MM/y, ms": "dd/MM/y, mm:ss",
        "d, ms": "d, mm:ss",
        "E, ms": "ccc, mm:ss",
        "Ed, ms": "E d, mm:ss",
        "Gy, ms": "y G, mm:ss",
        "GyMd, ms": "d/M/y GGGGG, mm:ss",
        "GyMMM, ms": "MMM y G, mm:ss",
        "GyMMMd, ms": "d MMM y G, mm:ss",
        "GyMMMEd, ms": "E, d MMM y G, mm:ss",
        "M, ms": "L, mm:ss",
        "Md, ms": "dd/MM, mm:ss",
        "MEd, ms": "E, dd/MM, mm:ss",
        "MMdd, ms": "dd/MM, mm:ss",
        "MMM, ms": "LLL, mm:ss",
        "MMMd, ms": "d MMM, mm:ss",
        "MMMEd, ms": "E, d MMM, mm:ss",
        "MMMMd 'at' ms": "d MMMM 'at' mm:ss",
        "y, ms": "y, mm:ss",
        "yM, ms": "MM/y, mm:ss",
        "yMd, ms": "dd/MM/y, mm:ss",
        "yMEd, ms": "E, dd/MM/y, mm:ss",
        "yMMM, ms": "MMM y, mm:ss",
        "yMMMd, ms": "d MMM y, mm:ss",
        "yMMMEd, ms": "E, d MMM y, mm:ss",
        "yMMMM 'at' ms": "MMMM y 'at' mm:ss"
      }
    },
    "intervalFormats": {
      "intervalFormatFallback": "{0} – {1}",
      "Bh": {
        "B": "h B – h B",
        "h": "h – h B"
      },
      "Bhm": {
        "B": "h:mm B – h:mm B",
        "h": "h:mm – h:mm B",
        "m": "h:mm – h:mm B"
      },
      "d": {
        "d": "d–d"
      },
      "Gy": {
        "G": "y G – y G",
        "y": "y – y G"
      },
      "GyM": {
        "G": "M/y GGGGG – M/y GGGGG",
        "M": "M/y – M/y GGGGG",
        "y": "M/y – M/y GGGGG"
      },
      "GyMd": {
        "d": "dd/MM/y – dd/MM/y GGGGG",
        "G": "dd/MM/y GGGGG – dd/MM/y GGGGG",
        "M": "dd/MM/y – dd/MM/y GGGGG",
        "y": "dd/MM/y – dd/MM/y GGGGG"
      },
      "GyMEd": {
        "d": "E, dd/MM/y – E, dd/MM/y GGGGG",
        "G": "E, dd/MM/y GGGGG – E, dd/MM/y GGGGG",
        "M": "E, dd/MM/y – E, dd/MM/y GGGGG",
        "y": "E, dd/MM/y – E, dd/MM/y GGGGG"
      },
      "GyMMM": {
        "G": "MMM y G – MMM y G",
        "M": "MMM – MMM y G",
        "y": "MMM y – MMM y G"
      },
      "GyMMMd": {
        "d": "d – d MMM y G",
        "G": "d MMM y G – d MMM y G",
        "M": "d MMM – d MMM y G",
        "y": "d MMM y – d MMM y G"
      },
      "GyMMMEd": {
        "d": "E, d MMM – E, d MMM y G",
        "G": "E, d MMM y G – E, d MMM y G",
        "M": "E, d MMM – E, d MMM y G",
        "y": "E, d MMM y – E, d MMM y G"
      },
      "h": {
        "a": "h a – h a",
        "h": "h–h a"
      },
      "H": {
        "H": "HH–HH"
      },
      "hm": {
        "a": "h:mm a – h:mm a",
        "h": "h:mm – h:mm a",
        "m": "h:mm – h:mm a"
      },
      "Hm": {
        "H": "HH:mm–HH:mm",
        "m": "HH:mm–HH:mm"
      },
      "hmv": {
        "a": "h:mm a – h:mm a v",
        "h": "h:mm – h:mm a v",
        "m": "h:mm – h:mm a v"
      },
      "Hmv": {
        "H": "HH:mm–HH:mm v",
        "m": "HH:mm–HH:mm v"
      },
      "hv": {
        "a": "h a – h a v",
        "h": "h–h a v"
      },
      "Hv": {
        "H": "HH–HH v"
      },
      "M": {
        "M": "M–M"
      },
      "Md": {
        "d": "dd/MM – dd/MM",
        "M": "dd/MM – dd/MM"
      },
      "MEd": {
        "d": "E dd/MM – E dd/MM",
        "M": "E dd/MM – E dd/MM"
      },
      "MMM": {
        "M": "MMM – MMM"
      },
      "MMMd": {
        "d": "d–d MMM",
        "M": "d MMM – d MMM"
      },
      "MMMEd": {
        "d": "E d MMM – E d MMM",
        "M": "E d MMM – E d MMM"
      },
      "y": {
        "y": "y–y"
      },
      "yM": {
        "M": "MM/y – MM/y",
        "y": "MM/y – MM/y"
      },
      "yMd": {
        "d": "dd/MM/y – dd/MM/y",
        "M": "dd/MM/y – dd/MM/y",
        "y": "dd/MM/y – dd/MM/y"
      },
      "yMEd": {
        "d": "E, dd/MM/y – E, dd/MM/y",
        "M": "E, dd/MM/y – E, dd/MM/y",
        "y": "E, dd/MM/y – E, dd/MM/y"
      },
      "yMMM": {
        "M": "MMM – MMM y",
        "y": "MMM y – MMM y"
      },
      "yMMMd": {
        "d": "d–d MMM y",
        "M": "d MMM – d MMM y",
        "y": "d MMM y – d MMM y"
      },
      "yMMMEd": {
        "d": "E, d MMM – E, d MMM y",
        "M": "E, d MMM – E, d MMM y",
        "y": "E, d MMM y – E, d MMM y"
      },
      "yMMMM": {
        "M": "MMMM – MMMM y",
        "y": "MMMM y – MMMM y"
      }
    },
    "hourCycle": "h23",
    "nu": [
      "latn"
    ],
    "ca": [
      "gregory"
    ],
    "hc": [
      "h23",
      "h12",
      "",
      ""
    ]
  },
  "locale": "en-GB"
}
)
}
