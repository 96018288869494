"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stream_1 = require("stream");
var CSV_1 = require("./CSV");
var Parser_1 = require("./Parser");
var Streamer = /** @class */ (function (_super) {
    __extends(Streamer, _super);
    function Streamer(options) {
        var _this = _super.call(this, {
            readableObjectMode: true,
            writableObjectMode: false,
        }) || this;
        // Transform.call(this, );
        _this.buffer = '';
        _this.sep = options && options.separator;
        _this.quo = options && options.quote;
        return _this;
    }
    // overridden function with same signature
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Streamer.prototype._transform = function (chunk, _encoding, callback) {
        var _this = this;
        this.buffer = this.buffer.concat(chunk.toString());
        if (this.sep === undefined) {
            // try to detect the separator if not provided
            this.sep = CSV_1.detect(this.buffer);
        }
        var csv = new Parser_1.Parser(this.buffer, this.sep, this.quo);
        var rows = csv.File();
        if (csv.linePointer !== csv.pointer) {
            rows.pop();
        }
        this.buffer = this.buffer.slice(csv.linePointer);
        if (rows.length > 0) {
            rows.forEach(function (row) {
                _this.push(row);
            });
        }
        callback();
    };
    // TODO
    /*
    push(chunk: any, encoding?: string | undefined): boolean {
      throw new Error("Method not implemented.");
    }
    */
    Streamer.prototype._flush = function (callback) {
        var _this = this;
        var csv = new Parser_1.Parser(this.buffer, this.sep, this.quo);
        var rows = csv.File();
        if (rows.length > 0) {
            rows.forEach(function (row) {
                _this.push(row);
            });
        }
        callback();
    };
    return Streamer;
}(stream_1.Transform));
exports.Streamer = Streamer;
